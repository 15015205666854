import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Button } from 'react-bootstrap'
import { useStyles } from './styles'

import LanguageModal from '../LanguagesModal'
import { languages } from '../../utils/languages'
const LangCta = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [language, setLanguage] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const fetch = () => {
      const lang = window.Weglot.getCurrentLang()
      setLanguage(lang)
      setIsMobile(/Android|iPhone|iPad|iPod/i.test(navigator.userAgent))
      setLoaded(true)
    }

    setTimeout(() => fetch(), 400)
  }, [])

  const changeLanguage = (code) => {
    window.Weglot.on('languageChanged', (newLang) => {
      if (newLang === code) {
        window.location.reload()
      }
    })

    window.Weglot.switchTo(code)

    setOpen(false)
  }

  const handleLangPopupState = () => {
    setOpen(false)
  }

  return (
    <div className={classes.footerLang}>
      <Grid className={classes.mainViewCta}>
        <Button
          className="lang-button no_translate blackText"
          onClick={() => setOpen(true)}
        >
          <img
            role="none"
            alt="language-flag"
            src={
              language && language !== ''
                ? languages.find((lang) => lang.code === language).flag
                : null
            }
            className="lang-flag"
          />
          {language && language !== ''
            ? languages.find((lang) => lang.code === language).name
            : ''}
        </Button>
      </Grid>
      <LanguageModal
        isOpen={open}
        setClose={changeLanguage}
        languages={languages}
        selectedLang={language}
        cancel={handleLangPopupState}
      />
      <hr />
    </div>
  )
}

export { LangCta }
